#cookies-info {
    display: none;
    padding: 1.5rem 2rem;
    padding-right: 5rem !important;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #f4f4f4;
    color: #6f6f6f;
    z-index: 1000;
    border-top: 1px solid #d0cece;
    width: 100% !important;
}

#cookies-info svg {
    width: 3.5rem;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    color: #0E4194;
    margin-right: 1rem;
    padding-right: 1rem;
    border-right: 1px solid #dee2e6;
    margin-left: 1.5rem;
}

.d-flex {
    display: flex !important;
}

.policy-link {
    color: #0E4194;
    text-decoration: underline !important;
}

.policy-link:hover {
    filter: brightness(1.5);
}

#cookies-info path {
    fill: #034EA2;
}

.btn-wrapper {
    margin-top: 1rem !important;
}

#cookies-info .btn {
    background: linear-gradient(90deg, #0E4194 0%, #034EA2 100%);
    color: white;
    text-transform: uppercase;
    line-height: 1.5em;
    border-radius: 5rem;
    box-shadow: none !important;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1.3rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-decoration: none;
    cursor: pointer;
}

#cookies-info .btn:hover {
    filter: brightness(1.5);
}